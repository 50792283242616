import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Col, Row, Button, AutoComplete, Spin } from 'antd';
import { FlatList } from 'react-native-web';

import { Card } from './Card';

const BlogRoll = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  const [state, setState] = React.useState({
    data: [],

    filteredVerses: [],

    categories: [],
    category: '',
  });

  React.useEffect(() => {
    setState({
      ...state,
      data: posts,
    });

    for (let index = 0; index < posts.length; index++) {
      const node = posts[index];
      const categories = node.node.frontmatter.categories;

      for (let i = 0; i < categories.length; i++) {
        const c = categories[i];
        if (c.trim() !== '' && state.categories.indexOf(c) === -1) {
          state.categories.push(c);
        }
      }
    }
  }, [posts]);

  function filter(value) {
    const filteredVerses = state.data
      .filter((p) => {
        const cats = p.node.frontmatter.categories.map((c) => c.toUpperCase());
        return cats.indexOf(value.toUpperCase()) > -1;
      })
      .map((v) => v.node);

    setState({ ...state, filteredVerses, category: value });
  }

  function renderList() {
    const { data, filteredVerses } = state;

    if (filteredVerses && filteredVerses.length) {
      return filteredVerses.map((node, i) => (
        <Col
          key={i}
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 20, offset: 2 }}
          lg={{ span: 10, offset: 7 }}
          xl={{ span: 10, offset: 7 }}
          xxl={{ span: 10, offset: 7 }}
        >
          <Card link={node.fields.slug} verse={node} />
        </Col>
      ));
    }

    return (
      <FlatList
        data={state.data}
        onEndReachedThreshold={0.9}
        renderItem={({ item }) => {
          const post = item.node;
          return (
            <Col
              key={post.title}
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              md={{ span: 20, offset: 2 }}
              lg={{ span: 10, offset: 7 }}
              xl={{ span: 10, offset: 7 }}
              xxl={{ span: 10, offset: 7 }}
            >
              <Card link={post.fields.slug} verse={post} />
            </Col>
          );
        }}
      />
    );
  }

  function reset() {
    setState({ ...state, category: '', filteredVerses: [] });
  }

  function handleSelect(value) {
    filter(value);
  }

  if (!data || data.length < 1) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>No data yet.</p>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 20, offset: 2 }}
          lg={{ span: 10, offset: 7 }}
          xl={{ span: 10, offset: 7 }}
          xxl={{ span: 10, offset: 7 }}
          style={{
            backgroundColor: '#fff',
            boxShadow: '0 0 30px rgba(0,0,0,0.1)',
            padding: '16px 16px',
          }}
        >
          <div className="auto-input mb-md">
            <label>Choose a category</label>
            <AutoComplete
              dataSource={state.categories}
              onSelect={(value) => handleSelect(value)}
              placeholder="Select a category"
              value={state.category}
            />
          </div>
          <div className="mb-md end">
            <Button onClick={reset}>Reset</Button>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: 30 }}>{renderList()}</Row>
    </div>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        file(relativePath: { eq: "uploads/default-ipd.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___createdAt] }
          filter: {
            frontmatter: {
              templateKey: { eq: "blog-post" }
              visible: { eq: true }
            }
          }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                templateKey
                title
                createdAt(formatString: "MMMM DD, YYYY")
                scripture
                location
                categories
                location
                visible
                featuredImage {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll key={count} data={data} count={count} />}
  />
);
