import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import { TagList } from '../TagList';

import './card.scss';

export const Card = props => {
  const { verse: v, link } = props;
  const verse = v.frontmatter;

  return (
    <div className="ipd-card">
      <Link to={link}>
        <Img
          fluid={
            verse.featuredImage ? verse.featuredImage.childImageSharp.fluid : ''
          }
          alt={`${verse.title}`}
        />
        <div className="ipd-card__body">
          <div>
            <h1>{verse.title}</h1>
            <h4>
              {verse.createdAt} - {verse.location}
            </h4>
          </div>
          <p>{verse.scripture.substring(0, 120)}</p>
          <TagList type="card" tags={verse.categories} />
        </div>
      </Link>
    </div>
  );
};
